import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Factory, FactoryResponse } from './factory.model';
import { Pagination } from 'app/core/api/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class FactoryService {

    private _factory: BehaviorSubject<Factory[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    pagination: Pagination;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for categories
     */
    get factories$(): Observable<Factory[]> {
        return this._factory.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get event list 
     */
    getFactoriesInitial(fromAdmin: boolean = false): Observable<FactoryResponse> {
        return this.getFactories(null, null, null, null, null, fromAdmin)
    }

    getFactories(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, fromAdmin: boolean = false): Observable<FactoryResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;

        let filter = {
            page: page ? ++page : 1,
            ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10,
        }
        if (fromAdmin == true)
            filter['admin'] = 1
        return this._httpClient.get<FactoryResponse>(`${BASE_URL}organization/factory/`, {
            params: filter
        }).pipe(
            tap((response) => {
                if (response?.data?.length == 0)
                    this.errorMessage = "There are no factories to display!"
                this._factory.next(response?.data);
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
            })
        );
    }

    getFactoryDetails(categoryId): Observable<Factory> {
        return this._httpClient.get<Factory>(`${BASE_URL}organization/factory/${categoryId}/`)
    }

    deleteFactory(eventId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}factory/${eventId}/`).pipe(
            tap((res: any) => {
                this._factory.next(this._factory.value.filter(event => event.id !== eventId));
            }, err => of([]))
        )
    }

    createFactory(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}organization/factory/`, credentials);
    }

    updateFactory(credentials, eventId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}organization/factory/${eventId}/`, credentials)
    }

}
